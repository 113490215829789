import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Seite nicht gefunden" />
    <h1>Hier existiert leider nichts!</h1>
    <p>Dieser Link führte leider ins Nichts.</p>
      <Link to="/" className="button orange">Zurück zur Startseite</Link>
  </Layout>
);

export default NotFoundPage
